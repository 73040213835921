<template>
  <div>
    <p class="title">人工代充</p>
    <div class="agent_search">
      <div style="padding-top:10px">
        <label class="lab" for="searchname">商品名称</label>
        <el-input clearable name="searchname" placeholder="请输入商品名称" class="goods_search" v-model="goodsname"></el-input>
        <label class="lab" for="select">商品状态</label> <el-select v-model="orderstate" clearable placeholder="请选择订单状态"
          name="select" @change="gettable(1)">
          <el-option v-for="item in aoptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

        <Button type="primary" style="margin:0 5px 0 5px" @click="gettable(1)">搜索</Button>
        <Button type="primary" @click="reset">重置</Button>
      </div>
      <div>
        <ul class="letter_search">
          <label style="float:left;font-size:16px;margin-left:10px;">首字母搜索</label>
          <li v-for="(item, index) in letter" :key="index" @click="s_letter_search(item, index)"
            :class="{ 'active': cla == index }">
            {{ item }}
          </li>
        </ul>
      </div>
      <div style="width:100%;margin:60px auto 0 auto">
        <el-table ref="multipleTable" border height='600' :data="tableData" tooltip-effect="dark" style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column label="商品编码" width="100" prop="good_coding">
          </el-table-column>
          <el-table-column prop="good_name" label="商品名称">
          </el-table-column>
          <el-table-column label="商品状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.is_release == 1">已发布</span>
              <span v-if="scope.row.is_release == 2">未发布</span>
              <span v-if="scope.row.is_release == 3">维护中</span>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="销售价">
          </el-table-column>
          <el-table-column prop="type_str" label="商品类型">
          </el-table-column>
          <el-table-column prop="supplier_str" label="商品归属">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" v-if="scope.row.is_release == 1" @click="unpublish(scope.row)">取消发布</el-button>
              <el-button type="text" v-if="scope.row.is_release == 2" @click="release(scope.row)">发布</el-button>
              <el-button type="text" v-if="scope.row.is_release == 3" @click="unmaintain(scope.row)">取消维护</el-button>
              <el-button type="text" @click="edit(scope.row)">编辑</el-button>
              <el-button type="text" @click="del(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="fy" style="margin-top:5px" background prev-text='上一页' next-text='下一页'
          layout="total , prev, pager, next, jumper" @current-change="current_change" :total="total" :page-size="pagesize"
          :current-page.sync="currentPage">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['chanlist'],
  data() {
    return {
      orderstate: "",
      aoptions: [
        {
          value: '1',
          label: '已发布'
        },
        {
          value: '2',
          label: '未发布'
        }, {
          value: '3',
          label: '维护中'
        }],
      currentPage: 1,
      pagesize: 10,
      total: 0,
      tableData: [],
      checked: [],
      english: "",
      cla: -1,
      goodsname: "",
      letter: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],//26字母

    }
  },
  mounted() {
    this.chanlist("1-4", '1')
    this.gettable()
  },
  methods: {
    unmaintain(row) {
      this.$confirm('您确定要取消维护此商品？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: 'warning'
        center: true
      }).then(() => {
        this.$axios.post('/supplier/good/release', { type: 1, gids: row.id }).then((res) => {
          if (res.data.code == 20000) {
            this.$message({
              type: 'success',
              message: res.data.message
            });
            this.gettable(1)
          } else {
            this.$message({
              type: 'warning',
              message: res.data.message
            });
          }
        })
      })
    },
    unpublish(row) {
      this.$confirm('您确定要取消发布此商品？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: 'warning'
        center: true
      }).then(() => {
        this.$axios.post('/supplier/good/release', { type: 2, gids: row.id }).then((res) => {
          if (res.data.code == 20000) {
            this.$message({
              type: 'success',
              message: res.data.message
            });
            this.gettable(1)
          } else {
            this.$message({
              type: 'warning',
              message: res.data.message
            });
          }
        })
      })
    },
    edit(row) {
      this.$router.push({ name: "Edit", params: { row: row, list: 3 } })
    },
    del(row) {
      if (row.is_release == 1) {
        this.$message({
          type: "warning",
          message: "还有正在出售的商品，请先取消发布"
        })
        return
      }
      this.$confirm('您是否要删除此商品？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: 'warning'
        center: true
      }).then(() => {
        this.$axios.post('/supplier/good/rack.up.and.down', { gid: row.id, rack_up_and_down: 2 }).then((res) => {
          if (res.data.code == 20000) {
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            this.gettable(1)
          } else {
            this.$message({
              type: 'warning',
              message: res.data.message
            });
          }
        })

      })
    },
    release(row) {
      this.$confirm('您确定要发布此商品？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: 'warning'
        center: true
      }).then(() => {
        this.$axios.post('/supplier/good/release', { type: 1, gids: row.id }).then((res) => {
          if (res.data.code == 20000) {
            this.$message({
              type: 'success',
              message: res.data.message
            });
            this.gettable(1)
          } else {
            this.$message({
              type: 'warning',
              message: res.data.message
            });
          }
        })

      })
    },
    current_change(page) {
      this.currentPage = page;
      this.gettable()
    },
    handleSelectionChange(val) {
      this.checked = val;
    },
    reset() {
      this.goodsname = ''
      this.orderstate = ''
      this.english = ''
      this.cla = -1
      this.gettable(1)
    },
    s_letter_search(item, index) {
      this.cla = index;
      this.english = item
      this.gettable(1)
    },
    gettable(page) {
      if (page == 1) {
        this.currentPage = 1
      }
      this.$axios.post("/supplier/good/list/page", { page: this.currentPage, limit: this.pagesize, type: 3, initials: this.english, good_name: this.goodsname, is_release: this.orderstate })
        .then((res) => {
          if (res.data.code == 20000) {
            this.tableData = res.data.data.rows
            this.total = res.data.data.total
          } else {
            this.tableData = []
            this.total = 0
          }
        })
    }
  },
}
</script>

<style>
.title {
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  border-bottom: 1px solid gray;
  text-align: left;
  font-size: 20px;
}

.agent_search {
  width: 99%;
  height: 100px;
  border: 1px solid #ccc;
  margin-top: 10px;
  float: left;
  text-align: left;
  margin-left: 10px;
}

.lab {
  font-size: 16px;
  margin: 10px 10px 10px 20px;
  display: inline-block
}

.goods_search {
  width: 220px;
  height: 25px;
  border-color: initial;
  margin-left: 3px
}

.active {
  background: #3743D6;
  color: #fff;
}

.letter_search {
  height: 20px;
  list-style: none;
  text-align: center;
  display: block;
  width: 900px;
  margin: 10px auto;
  line-height: 20px;
  float: left;
}

.letter_search li {
  float: left;
  height: 18px;
  display: block;
  width: 30px;
  cursor: pointer;
}

.fy {
  text-align: center;
  padding-top: 5px;
}
</style>